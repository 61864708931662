import React, { useState } from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import { useSpring, animated } from 'react-spring';

import Header from '../components/header';
import Footer from '../components/footer';
import Content from '../components/content';
import Icon from '../components/icon';
import ExperienceModal from '../components/modal';
import SEO from '../components/seo';

import productStyles from '../styles/product.module.less';

import moduleImage1 from '../images/product/product-module-1.png';
import moduleImage2 from '../images/product/product-module-2.png';
import moduleImage3 from '../images/product/product-module-3.png';
import moduleImage4 from '../images/product/product-module-4.png';

import productImage1 from '../images/product/product-img1.png';
import productImage2 from '../images/product/product-img2.png';
import productImage3 from '../images/product/product-img3.png';
import productImage4 from '../images/product/product-img4.png';
import productImage5 from '../images/product/product-img5.png';
import productImage6 from '../images/product/product-img6.png';

export default () => {
    let [_index, setIndex] = useState(0);
    let [distance, move] = useState(0);
    const [showModal, setShowModal] = useState(false);
    let moveDistance = 170;

    let presentationList = [
        {
            header: {
                title: '多店铺同时管理',
                content: '库存实时同步易打理'
            },
            title: {
                title:
                    '一步注册店货通，将线上店铺实体店铺对接至店货通平台， 通过SaaS化平台的操作，整合资源统一管理。',
                content: '多店铺统一管理，订单分秒同步 成为网店与实体店间的强力纽带!'
            },
            img: {
                url: productImage1,
                style: { textAlign: 'right' }
            }
        },
        {
            header: {
                title: '真正适配实体店的管理方式',
                content: '告别复杂的“假门店”操作'
            },
            title: {
                title: '即支持线下门店做网店仓库的O2O模式，同时也支持门店独立管理模式',
                content: '开启O2O门店管理新模式，升级新零售的必备店铺管理工具'
            },
            img: {
                url: productImage2,
                style: { textAlign: 'left' }
            }
        },
        {
            header: {
                title: '系统坚实稳定',
                content: '零卡顿、零漏单护航各种大促'
            },
            title: {
                title: '全新的店货通，系统稳固再升级 支持单店单日订单量达百万'
            },
            img: {
                url: productImage3,
                style: { textAlign: 'right' }
            }
        },
        {
            header: {
                title: '精细化线上线下店铺统一管理'
            },
            title: {
                title:
                    '进销存为一体功能全面，涵盖进货、销售、库存查询、 客户及供应商对账等经营场景中的所有业务，多仓库 和分仓库管理更加便捷，使用软件管理即可轻松解决'
            },
            img: {
                url: productImage4,
                style: { textAlign: 'center' }
            }
        },
        {
            header: {
                title: '智能化库存上架',
                content: '盘点降低库存损失'
            },
            title: {
                title:
                    '智能化库存上架、盘点、预警、验货商品漏拣错拣不再发生商品智能化管理，运行稳定，功能便捷能更大程度减少人员配置和设备成本预警提醒、降低风险',
                content:
                    '通过库存预警及时掌握畅销滞销状况， 合理安排补货或促销计划，降低滞销、脱销风险'
            },
            img: {
                url: productImage5,
                style: { textAlign: 'center' }
            }
        },
        {
            header: {
                title: '自动生成各种报表',
                content: '降低人力成本'
            },
            title: {
                title:
                    '行业领先的专业全渠道财务管理软件，数据安全有保障 支持各种库存、财务、订单处理记录等各种报表查看'
            },
            img: {
                url: productImage6,
                style: { textAlign: 'center' }
            }
        }
    ];

    let featureList = [
        {
            icon: 'icondingdanguanli',
            name: '订单管理'
        },
        {
            icon: 'iconcaigoushicai',
            name: '采购管理'
        },
        {
            icon: 'iconicon-p_dangqiankucun',
            name: '仓储管理'
        },
        {
            icon: 'iconshangpin',
            name: '商品管理'
        },
        {
            icon: 'iconcaiwu',
            name: '财务管理'
        },
        {
            icon: 'iconyuangongguanli',
            name: '员工管理'
        }
    ];

    let featureContentList = [
        {
            icon: 'icondingdanguanli',
            title: '订单管理',
            content: '支持订单自动下载、批量导入和导出处理 订单异常提醒、订单售后、操作日志等'
        },
        {
            icon: 'iconcaigoushicai',
            title: '采购管理',
            content:
                '支持采购计划、生成采购单、采购入库、支持智能采购模式、供应商管理、供应商结算等'
        },
        {
            icon: 'iconicon-p_dangqiankucun',
            title: '仓储管理',
            content:
                '支持多种库存同步模式管理、多仓库管理、仓库区位划分、实时盘点、仓间调拨、库存预警、 出入库统计等'
        },
        {
            icon: 'iconshangpin',
            title: '商品管理',
            content:
                '支持商品信息维护，买赠满送等活动促销管理，商品批次及保质期管理，细致化货号、SKU、唯一码管理， 同一商品多种库存同步（占货）规则等'
        },
        {
            icon: 'iconcaiwu',
            title: '财务管理',
            content:
                '支持收付款单管理、采购单审核结算库存 成本管理、应退款管理、即时查看各类分析报表'
        },
        {
            icon: 'iconyuangongguanli',
            title: '员工管理',
            content:
                '支持按员工角色、店铺、仓库等维度进行权限分配，新增、删除、编辑子账号，便于企业协同管理'
        }
    ];

    let moduleList = [
        {
            title: '店铺下单',
            content: '订单信息同步，随时跟踪',
            icon: moduleImage1
        },
        {
            title: 'ERP集成',
            content: 'ERP系统应用集成，打通内外连接',
            icon: moduleImage2
        },
        {
            title: '自定义字段',
            content: '自由设定、实现编辑预定时间、预定地点等',
            icon: moduleImage3
        },
        {
            title: '数据报表',
            content: '自动生成，刷新页面即可实时更新',
            icon: moduleImage4
        }
    ];

    const ulMove = (type) => {
        if (type === 'left' && distance > 0) {
            move((distance -= moveDistance));
        } else if (type === 'right' && distance < moveDistance * (featureList.length - 4)) {
            move((distance += moveDistance));
        }
    };

    const animation = useSpring({
        right: distance
    });

    const handleCancel = () => {
        setShowModal(false);
    };

    return (
        <div>
            <SEO title="产品介绍" />
            <Header />
            <section className={productStyles.productBannerOutside}>
                <div
                    className={productStyles.productBannerContent}
                    style={{ width: '1200px', margin: '0 auto', position: 'relative' }}
                >
                    <div className={productStyles.productBannerContent__text}>
                        <h2>
                            实用型线上线下<span>ERP</span>
                        </h2>
                        <h3>轻松管理商家多店铺生意</h3>
                        <div>
                            <p>国内领先的SaaS化新零售管理软件</p>
                        </div>
                        <button onClick={() => setShowModal(true)}>免费试用</button>
                        <p>
                            · 商品管理 · 店铺管理 · 采购管理 · 库存管理 · 销售管理 · 订单管理 ·
                            仓库管理 · 财务管理
                        </p>
                    </div>
                </div>
            </section>

            {presentationList.map((item, index) => {
                return (
                    <section
                        key={index}
                        className={
                            index % 2 === 0
                                ? productStyles.product__outside_default
                                : productStyles.product__outside_dark
                        }
                    >
                        <Content
                            title={index === 0 && '轻松打理你的店铺'}
                            titleStyle={{ fontSize: '30px' }}
                            descriptionStyle={{ fontSize: '20px' }}
                            description={
                                index === 0 &&
                                '无需实施安装，功能全面，注册即可准确掌握店铺的整体运营情况'
                            }
                        >
                            <div
                                className={productStyles.productPresentation__content}
                                style={index === 0 ? { marginTop: '107px' } : { marginTop: 0 }}
                            >
                                <Row gutter={200}>
                                    {index % 2 === 0 ? (
                                        <>
                                            <Col span={11}>
                                                <div
                                                    className={
                                                        productStyles.productPresentation__content_header
                                                    }
                                                >
                                                    {item.header.title && (
                                                        <h3>{item.header.title}</h3>
                                                    )}
                                                    {item.header.content && (
                                                        <h3>{item.header.content}</h3>
                                                    )}
                                                    <p></p>
                                                </div>
                                                <div
                                                    className={
                                                        productStyles.productPresentation__content_title
                                                    }
                                                >
                                                    {item.title.title && (
                                                        <h5>{item.title.title}</h5>
                                                    )}
                                                    {item.title.content && (
                                                        <p>{item.title.content}</p>
                                                    )}
                                                </div>
                                                <button
                                                    className={
                                                        productStyles.productPresentation__content_button
                                                    }
                                                    onClick={() => setShowModal(true)}
                                                >
                                                    免费体验
                                                </button>
                                            </Col>
                                            <Col span={13}>
                                                <i
                                                    className={
                                                        productStyles.productPresentation__content_images
                                                    }
                                                    style={item.img.style}
                                                >
                                                    <img src={item.img.url} alt="" />
                                                </i>
                                            </Col>
                                        </>
                                    ) : (
                                        <>
                                            <Col span={13}>
                                                <i
                                                    className={
                                                        productStyles.productPresentation__content_images
                                                    }
                                                    style={item.img.style}
                                                >
                                                    <img src={item.img.url} alt="" />
                                                </i>
                                            </Col>
                                            <Col span={11}>
                                                <div
                                                    className={
                                                        productStyles.productPresentation__content_header
                                                    }
                                                >
                                                    {item.header.title && (
                                                        <h3>{item.header.title}</h3>
                                                    )}
                                                    {item.header.content && (
                                                        <h3>{item.header.content}</h3>
                                                    )}
                                                    <p></p>
                                                </div>
                                                <div
                                                    className={
                                                        productStyles.productPresentation__content_title
                                                    }
                                                >
                                                    {item.title.title && (
                                                        <h5>{item.title.title}</h5>
                                                    )}
                                                    {item.title.content && (
                                                        <p>{item.title.content}</p>
                                                    )}
                                                </div>
                                                <button
                                                    className={
                                                        productStyles.productPresentation__content_button
                                                    }
                                                    onClick={() => setShowModal(true)}
                                                >
                                                    免费体验
                                                </button>
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            </div>
                        </Content>
                    </section>
                );
            })}
            <section className={productStyles.product__outside_default}>
                <Content
                    title="功能覆盖进销存全流程"
                    description="SaaS化管理线上、线下店铺，无需下载软件，注册即可多端同步，随时随地使用"
                    titleStyle={{ fontSize: '30px' }}
                    descriptionStyle={{ fontSize: '20px' }}
                ></Content>
                <div className={productStyles.productFeature__content}>
                    <ul className={productStyles.productFeature__content__listDetails}>
                        {featureContentList.map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    className={
                                        _index === index
                                            ? productStyles.productFeature__content__listDetails__selected
                                            : ''
                                    }
                                >
                                    <Icon
                                        type={item.icon}
                                        style={{
                                            fontSize: '80px',
                                            color: '#fff',
                                            lineHeight: '100px'
                                        }}
                                    />
                                    <h4>{item.title}</h4>
                                    <p>{item.content}</p>
                                    <button onClick={() => setShowModal(true)}>立即体验</button>
                                </li>
                            );
                        })}
                    </ul>
                    <div className={productStyles.productFeature__content__listBox}>
                        <div className={productStyles.productFeature__content__listBox__fl}>
                            <Icon
                                type="iconzuojiantou_huaban"
                                style={{ fontSize: '50px', color: '#fff' }}
                                onClick={() => ulMove('left')}
                            />
                        </div>
                        <div className={productStyles.productFeature__content__listBox__fr}>
                            <Icon
                                type="iconyoujiantou_huaban"
                                style={{ fontSize: '50px', color: '#fff' }}
                                onClick={() => ulMove('right')}
                            />
                        </div>
                        <div className={productStyles.productFeature__content__listBox__list}>
                            <animated.h1 style={animation}>
                                <ul>
                                    {featureList.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <button onClick={() => setIndex((_index = index))}>
                                                    <Icon
                                                        type={item.icon}
                                                        style={{
                                                            fontSize: '50px',
                                                            color:
                                                                _index === index
                                                                    ? '#4e74ef'
                                                                    : '#888',
                                                            lineHeight: '110px'
                                                        }}
                                                    />
                                                    <p
                                                        style={{
                                                            color:
                                                                _index === index
                                                                    ? '#4e74ef'
                                                                    : '#888'        
                                                        }}
                                                    >
                                                        {item.name}
                                                    </p>
                                                </button>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </animated.h1>
                        </div>
                    </div>
                </div>
            </section>
            <Content
                title="中小企业的移动电商新模式"
                titleStyle={{ fontSize: '30px' }}
                descriptionStyle={{ fontSize: '20px' }}
                description="产品四大亮点，提升客户软件使用体验，开启您的移动营销之路"
            >
                <div className={productStyles.productModel}>
                    <Row>
                        {moduleList.map((item, index) => {
                            return (
                                <Col
                                    span={12}
                                    key={index}
                                    style={{ margin: '60px 0', textAlign: 'center' }}
                                >
                                    <Row>
                                        <Col span={8}>
                                            <i>
                                                <img src={item.icon} alt="" />
                                            </i>
                                        </Col>
                                        <Col span={16}>
                                            <div>
                                                <h4>{item.title}</h4>
                                                <p style={{ textAlign: 'left' }}>{item.content}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            </Content>
            <ExperienceModal showModal={showModal} onCancel={handleCancel} />
            <Footer />
        </div>
    );
};
